/**
 * Created by rsimm on 1/30/2016.
 */

"use strict";

var FooterBar = React.createClass({
    render: function(){
        return (
            <nav className="navbar navbar-inverse navbar-fixed-bottom">
                <div className="container-fluid">
                    <p className='navbar-text hidden-xs'>&copy; 2015 TCS Consultants, Inc.</p>
                    <div className='navbar-text pull-right'>
                        <a href='http://www.facebook.com/tcsconsultants' target='_blank'><i title='facebook' className='fa fa-facebook-square fa-2x' />&nbsp;</a>
                        <a href='http://www.twitter.com/tcs_consultants' target='_blank'><i title='twitter' className='fa fa-twitter-square fa-2x' />&nbsp;</a>
                        <a href='https://plus.google.com/u/0/+Tcstx/' target='_blank'><i title='google+' className='fa fa-google-plus-square fa-2x' />&nbsp;</a>
                        <a href='http://www.instagram.com/tcs_consultants' target='_blank'><i title='instagram' className='fa fa-instagram fa-2x' />&nbsp;</a>
                        <a href='http://www.youtube.com/c/tcstx' target='_blank'><i title='youtube' className='fa fa-youtube-square fa-2x' />&nbsp;</a>
                    </div>
                </div>
            </nav>
        );
    }
});

ReactDOM.render(<FooterBar />, document.getElementById('footerContents'));